export default {
  // Endpoints
  loginEndpoint: 'auth/signin',
  registerEndpoint: 'auth/signup',
  refreshEndpoint: 'auth/refresh-token',
  forgotEndpoint: 'auth/forgot-password',
  resetEndpoint: 'auth/change-password',
  logoutEndpoint: 'auth/logout',
  verifyLoginEndpoint: 'auth/verify-login',

  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken',
}
